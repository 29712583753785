<template>
  <div class="pay_box">
    <div class="addr_info">
      <div v-if="showConsignee" class="add_addr">
        <button class="btn_addr" @click="goAddress">+ 添加收货地址</button>
      </div>
      <div v-else class="addressee" @click="goAddress">
        <div>
          <div class="namephone">
            <div class="name_phone">
              <span class="name">{{
                detailData.shippingInformation.name
              }}</span>
              <span class="phone">{{
                detailData.shippingInformation.tel
              }}</span>
            </div>
          </div>
          <div class="address">
            <div class="address_txt">
              {{ detailData.shippingInformation.address }}
            </div>
          </div>
        </div>
        <div class="right_arrow">
          <img :src="require('@img/arrow_right_grey.png')" alt="" />
        </div>
      </div>
      <img :src="require('@img/order/group.jpg')" class="group" alt="" />
    </div>
    <!-- 填写处方 -->
    <div v-if="showPreInfo && !detailData.patientId" class="pre_info">
      <div class="fill_pre">
        <div class="left_fill">
          <div class="fill_in">填写处方信息</div>
          <div class="txt_in">处方药品, 需要凭处方购买</div>
        </div>
        <div class="right_fill">
          <Button class="right_btn" @click="goPre"> 去添加 </Button>
        </div>
      </div>
    </div>

    <!-- detailData.diseaseList.length -->
    <div v-if="detailData.patientId" class="completed_pre" @click="goPre">
      <div class="completed_info">
        <p class="completed_title">处方信息</p>
        <p class="completed_name">
          {{ detailData.drugManInformation.name || detailData.personName }}
          <span
            v-show="detailData.drugManInformation.name || detailData.personName"
            class="tag"
          >
            用药人
          </span>
        </p>
        <!-- 纸质图片 -->
        <p v-if="detailData.prescriptionType === 0" class="completed_txt">
          已上传处方
        </p>
        <!-- detailData.prescriptionType === 1 电子处方信息-->
        <p v-if="detailData.prescriptionType === 1" class="completed_txt">
          <span v-for="dis in detailData.diseaseList" :key="dis.diseaseName">
            {{ dis.diseaseName }}</span
          >
        </p>

        <p v-if="detailData.prescriptionType === 1" class="completed_txt">
          <span v-show="detailData.hasTake">无禁忌症;</span>
          <span v-show="!detailData.hasAllergy">无过敏史;</span>
          <span v-show="!detailData.hasAdverse"> 无不良反应;</span>
        </p>
      </div>
      <div class="right_arrow">
        <img :src="require('@img/arrow_right_grey.png')" alt="" />
      </div>
    </div>

    <div v-if="detailData.product.length" class="card">
      <CommonTitle
        :title="
          (detailData.product.length && detailData.product[0].merchantName) ||
          ''
        "
      />
      <div class="drug_list">
        <!-- drugData.memberPrice>0 以会员价方式显示 -->
        <DrugItem
          v-for="drugData in detailData.product"
          :key="drugData.goodsId"
          :data="drugData"
          :is-pay="isMember > 0 && drugData.memberPrice > 0 ? false : true"
        >
          <template v-slot:numOrCount>
            <p class="num">x {{ drugData.count }}</p>
          </template>
        </DrugItem>
        <Cost
          :dis-cos="detailData.freight"
          :sum-money="
            isMember && containPrice && Number(memberPrice) > 0
              ? Number(memberPrice)
              : Number(productSum)
          "
        />
      </div>
    </div>

    <div class="common_info">
      <CommonTitle title="会员卡" @commonTitleClick="handleCouponCellClick">
        <template v-slot:orderStatus>
          <div v-show="couponCellStatus === 0" class="coupon">暂无可用</div>
          <div
            v-show="hasSelectedCardAmount && couponCellStatus === 1"
            class="card_amount"
          >
            -¥{{ hasSelectedCardAmount.toFixed(2) }}
          </div>
          <div
            v-show="hasSelectedCardAmount === 0 && couponCellStatus === 2"
            class="unselected_coupon"
          >
            {{ Number(defaultDeductAmount).toFixed(2) }}元可用
          </div>
          <div class="arrow_container">
            <img
              :src="require('@img/arrow_right_grey.png')"
              class="arrow_right"
            />
          </div>
        </template>
      </CommonTitle>
    </div>

    <div class="common_info">
      <CommonTitle title="需要发票 (个人)">
        <template v-slot:orderStatus>
          <div class="sw">
            <!-- :class="{'mr_left': switchChecked }" -->
            <MySwitch
              v-model="switchChecked"
              active-color="#f36218"
              inactive-color="#DCDFE4"
              size="30"
              @change="switchChange"
            />
          </div>
        </template>
      </CommonTitle>

      <div v-show="switchChecked">
        <Field
          v-model.trim="detailData.invoice"
          label="发票抬头"
          maxlength="32"
          label-class="self_label"
          placeholder="请输入个人发票抬头"
          @blur="blurInvoice"
        />
      </div>
    </div>

    <div class="common_info">
      <CommonTitle title="购买须知" />
      <div class="purchase">
        <p>1. 选购处方药，需上传处方单图片</p>
        <p>
          2. 如需客服协助，请致电热线<a :href="'tel:' + '400 052 3990'"
            >400 052 3990</a
          >
        </p>
        <p>3. 人工客服工作时间：周一~周五09:00-18:00</p>
        <p v-if="!isJian1Bao">4. 订单满{{ packageMailAmount }}元包邮</p>
      </div>
    </div>

    <div class="footer_btns">
      <div class="sum_btn">
        <span class="sum_txt">实付:</span>
        <span v-if="isMember === '1'">
          <span class="money"
            ><span class="icon_money">¥</span>{{ actualPrice }}</span
          >
          <span
            v-if="isMember === '1' && containPrice"
            :class="{ origin_price: isMember === '1' }"
            class="money"
            >￥{{ allSum }}</span
          >
        </span>
        <span v-else class="money">￥{{ actualPrice }}</span>
      </div>
      <div class="pay_btn" @click="handlePay">{{ payBtnText }}</div>
    </div>

    <Dialog
      v-model.trim="showDialog"
      :confirm-button-color="'#F76D32'"
      confirm-button-text="知道了"
      width="280px"
      class="dialog"
      @confirm="handleConfirm"
    >
      <div class="tip">
        {{ tips }}
      </div>
    </Dialog>

    <coupon-select
      ref="refCouponSelect"
      :available-list="availableList"
      :unavailable-list="unavailableList"
      :total-amount="totalAmount"
      @toggleCouponItemSelect2="handleToggleCouponItemSelect"
      @handleCouponConfirm="handleCouponConfirm"
    />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import {
  Field, Switch, Button, Toast, Dialog,
} from 'vant';
import { divide } from '@/utils/calculate';
import CommonTitle from '@/components/order/CommonTitle';
import DrugItem from '@/components/order/drug-item';
import Cost from '@/components/order/cost';
import api from '@/api';
import Configs from '@/config';
import { mapMutations, mapActions, mapState } from 'vuex';
import getBaseHeaders from '@/utils/md5';
import { handleAfter401, getFreight } from '@/utils';
import couponMixin from '@/mixins/coupon';

export default {
  name: 'Pay',
  components: {
    CommonTitle,
    DrugItem,
    Cost,
    Field,
    MySwitch: Switch,
    Button,
    Dialog: Dialog.Component,
  },
  mixins: [couponMixin],
  data () {
    return {
      detailData: {
        // 配送信息
        shippingInformation: {
          name: '',
          tel: '',
          detailAddress: '',
          address: '',
          location: '',
          axis: {
            latitudes: '',
            longitudes: '',
          },
        },
        drugManInformation: {
          name: '',
          personId: '',
        },
        patientId: '',
        patientName: '',
        diseaseList: [],
        hasAdverse: '', // 不良反应
        hasAllergy: '', // 过敏反应
        hasTake: '', // 禁忌
        product: [],
        invoice: '',
        fileUrlList: [], // 电子的
        prescription: [], // 纸质的
        freight: 10,
        personName: '',
        gender: '',
        age: '',
        phone: '',
        prescriptionType: '',
      },
      switchChecked: false,
      showPreInfo: false,
      showConsignee: true,
      productSum: 0,
      allSum: 0,
      memberPrice: 0,
      allMemberPrice: 0,
      showDialog: false,
      tips: '',
      count: 0,
      isMember: sessionStorage.getItem('isMember') || 0,
      containPrice: false,
      isJian1Bao: false,
    };
  },

  computed: {
    ...mapState({
      drugStoreCode: (store) => store.merchantInfo.merchantObj.drugstoreCode,
    }),
    payBtnText () {
      return window.__wxjs_environment === 'miniprogram' ? '去支付' : '提交备货金'
    },
    packageMailAmount () {
      if (this.defaultSource !== '') {
        return Configs.postageConfig[this.defaultSource].packageMailAmount
      } else {
        return 168;
      }
    },
    defaultSource () {
      return localStorage.getItem('defaultSource') || ''
    }
  },

  created () {
    // 判断来源
    const defaultSource = localStorage.getItem('defaultSource') || '';
    this.isJian1Bao = defaultSource && defaultSource === Configs.jian1BaoSource
      ? 'true'
      : false;
    // 发票信息
    const { invoiceInfo } = this.$store.state.pay;
    // 药店名称 下单时用
    const { merchantObj } = this.$store.state.merchantInfo;
    // 收货地址信息
    const { consigneeInfo, QQAddress } = this.$store.state.consignee;
    // 处方信息
    const {
      diseases,
      diseaseInvestigation,
      eletronicPrescriptions,
      availablePrescriptions,
      drugUersSelected,
      currentTab,
    } = this.$store.state.prescription;
    if (diseases.length || availablePrescriptions.length) {
      this.detailData.patientId = drugUersSelected.personId;
      this.detailData.personName = drugUersSelected.personName;
      this.detailData.gender = drugUersSelected.gender;
      this.detailData.age = drugUersSelected.age;
      this.detailData.phone = drugUersSelected.phone;
      this.detailData.patientName = drugUersSelected.personName;
      this.detailData.diseaseList = diseases;
      this.detailData.fileUrlList = eletronicPrescriptions.map(
        (item) => item.url,
      ); // 电子处方（选填）
      this.detailData.prescription = availablePrescriptions.map(
        (item) => item.url,
      ); // 已有处方（必填）
      this.detailData.hasAdverse = diseaseInvestigation.adverseReaction; // 不良反应
      this.detailData.hasAllergy = diseaseInvestigation.allergy; // 过敏反应
      this.detailData.hasTake = diseaseInvestigation.taboo; // 禁忌
      this.detailData.prescriptionType = currentTab === 0 ? 1 : 0;
    }

    this.detailData.product = this.$store.state.shoppingCart.list;
    // product中添加药店名称
    for (let i = 0; i < this.detailData.product.length; i++) {
      this.productSum
        += this.detailData.product[i].count
        * this.detailData.product[i].productPrice; // 计算药品价格

      if (this.isMember > 0) {
        // 计算会员价
        if (this.detailData.product[i].memberPrice > 0) {
          this.containPrice = true; // 如果是会员，选的会员药，不显示划横线
        }
        this.memberPrice
          += this.detailData.product[i].count
          * (this.detailData.product[i].memberPrice > 0
            ? this.detailData.product[i].memberPrice
            : this.detailData.product[i].productPrice);
      }

      this.detailData.product[i].merchantName = merchantObj.name; // product中添加药店名称
      if (this.detailData.product[i].prescription === 1) {
        this.showPreInfo = true; // 判断有处方药才显示 添加处方信息
      }
    }
    this.productSum = this.productSum.toFixed(2);

    this.memberPrice = this.memberPrice.toFixed(2);
    //= 根据购物车合计数据 计算邮费
    this.detailData.freight = getFreight(this);

    // 计算合计
    this.allSum = Number(this.productSum) > 0
      ? (Number(this.productSum) + this.detailData.freight).toFixed(2)
      : 0;
    this.allMemberPrice = Number(this.memberPrice) > 0
      ? (Number(this.memberPrice) + this.detailData.freight).toFixed(
        2,
      )
      : 0;

    // 收获地址信息
    if (consigneeInfo.name) {
      this.showConsignee = false;
      this.detailData.shippingInformation = { ...consigneeInfo };
      if (QQAddress.province === QQAddress.city) {
        this.detailData.shippingInformation.location = QQAddress.province + QQAddress.district;
      } else {
        this.detailData.shippingInformation.location = QQAddress.province + QQAddress.city + QQAddress.district;
      }
      this.detailData.shippingInformation.address += this.detailData.shippingInformation.detailAddress;
      this.detailData.shippingInformation.axis.latitudes = QQAddress.location.lat;
      this.detailData.shippingInformation.axis.longitudes = QQAddress.location.lng;
    }

    if (invoiceInfo.invoice || invoiceInfo.switchChecked) {
      this.detailData.invoice = invoiceInfo.invoice;
      this.switchChecked = invoiceInfo.switchChecked;
    }

    // 获取会员卡
    // 区分情况

    // 如果用户没有选择过 那么加载初始默认选中的优惠策略 userAvailable
    if (!this.isCustom && this.detailData.product.length > 0) {
      // 用户没有自定义过
      this.getCouponList();
    } else if (this.detailData.product.length > 0) {
      this.userUpdateCouponSelect(this.cardIds).then((res) => {
        console.log('res', res);
        this.processCoupon(res);
        this.deductRelList = this.relation;
        this.hasSelectedCardAmount = res.data.totalAmount;
      });
    }
  },

  methods: {
    ...mapMutations('prescription', ['clearPrescriptionInfo']),
    ...mapMutations('merchantInfo', ['clearMerchantInfo']),
    ...mapMutations('consignee', ['clearConsigneeInfo']),
    ...mapActions('shoppingCart', ['emptyCartAsync']),
    ...mapMutations('pay', ['addInvoiceInfo', 'clearInvoiceInfo']),
    ...mapMutations('drugUsers', ['clearUserSelected']),
    goPre () {
      this.$router.push({
        name: 'prescription-info',
        params: {
          from: 'pay',
        },
      });
    },

    async handlePay () {
      this.count += 1;
      const parma = { ...this.detailData };
      console.log(parma, 'param')
      parma.merchantCode = this.$store.state.merchantInfo.merchantObj.drugstoreCode;
      parma.homeUrl = `${window.location.origin}/pysc/home`;
      parma.detailUrl = `${window.location.origin}/pysc/detail/`;
      parma.merchantCode = this.drugStoreCode;
      parma.count = this.count;
      parma.isMember = this.isMember;
      parma.shareCode = window.localStorage.getItem('shareCode') || undefined;
      if (parma.shareCode) {
        parma.qrCodeData = Configs.qrCodeData;
      }

      // md5加密
      parma.signature = getBaseHeaders(parma);

      // 会员卡
      parma.deductRelList = this.relation;

      const notPassVerify = this.verifyBeforePay();
      if (notPassVerify) return;

      if (window.__wxjs_environment === 'miniprogram') {
        // 是小程序环境
        parma.clientSide = 'applets';
        //派安康小程序
        const channelCode = localStorage.getItem('channelCode')
        if (channelCode === "PAK") { // 派安康来源
          parma.originStatus = 'PAK';
        }
      }

      // 判断企业微信环境
      const ua = window.navigator.userAgent;
      if (ua.includes && ua.includes('wxwork')) {
        parma.clientSide = 'wxwork';
      }


      if (
        sessionStorage.getItem('orderChannel')
        && sessionStorage.getItem('orderChannel') === 'jjga'
      ) {
        parma.originStatus = 'JQYRZ';
      }


      api.postCreateOrder(parma).then((res) => {
        if (res.data.code === '0') {
          // 创建成功，清除数据
          this.clearPrescriptionInfo();
          this.clearMerchantInfo();
          this.clearConsigneeInfo();
          this.clearInvoiceInfo();
          this.clearUserSelected();
          this.clearData();
          this.clearCustomCouponSelect();
          this.allMemberPrice = 0;
          this.allSum = 0;
          this.hasSelectedCardAmount = 0;
          localStorage.removeItem('shareCode');
          const { orderCacheId, mainOrderNo } = res.data.data;
          console.log(res, 'res==========merchantCode')
          // return;
          this.emptyCartAsync({
            merchantCode: parma.merchantCode,
            cb: () => {
              // 拉起收银台
              // 判断是否会员卡全额支付了
              if (window.__wxjs_environment === 'miniprogram' && localStorage.getItem('jumpMpPay') && localStorage.getItem('jumpMpPay') === 'yes') {
                // 微信环境 && 跳转小程序标识是 yes
                this.toMiniProgram(parma.merchantCode, res.data.data)
              } else if (res.data.data.paidFlag) {
                // 会员卡全额支付的订单, 直接跳转到支付成功页面
                window.location.href = `${Configs.payUrl}plugin/pages/success/index?paymentId=${orderCacheId}&type=1&isHiddenBackHomeBtn=undefined`;
              } else {
                // 正常需要走支付流程的订单
                window.location.href = `${Configs.payUrl}?orderCacheId=${orderCacheId}`;
              }
            },
          });
        } else {
          parma.count = 0;
          this.count = 0;
          Toast.fail({
            duration: 2000,
            message: res.data.message,
          });
        }

        // if (res.data.code === '21030' || res.data.code === '21912' || res.data.code === 'DO_ORDER_UPDATE_20026') {
        if (
          ['21030', 'DO_ORDER_UPDATE_20026', '21917'].includes(
            res.data.code,
          )
        ) {
          this.showDialog = true;
          this.tips = res.data.message;
        }

        if (res.data.code === '21912') {
          this.count = 0;
          // 腾讯来源的已经下过单了
          Dialog.alert({
            message: '思派云药房需要您授权登录',
            confirmButtonText: '去登录',
          }).then(() => {
            api.logout({
              token: localStorage.getItem('authentication'),
            }).then((logoutRes) => {
              if (logoutRes.data.code === '0') {
                localStorage.removeItem('authentication');
                localStorage.removeItem('userId');
                localStorage.removeItem('phone');
                handleAfter401();
              }
            });
          });
        }

        // 创建订单时, 会员卡余额变动, 弹窗提示
        if (res.data.code === '21921') {
          this.count = 0;
          Dialog.alert({
            message: res.data.message,
            confirmButtonText: '知道了',
          }).then(() => {
            this.userUpdateCouponSelect(this.cardIds).then(
              (result) => {
                this.processCoupon(result, true);
              },
            );
          });
        }
      });
    },
    // 跳转到微信小程序
    toMiniProgram (merchantCode, data) {
      const { amount, dateCreated, mainOrderNo, product } = data
      const query = {
        merchantCode,
        amount: divide(amount, 100),
        dateCreated,
        mainOrderNo,
        product,
        token: localStorage.getItem('authentication')
      }
      wx.miniProgram.navigateTo({
        url: `/pages/payment/pay/index?query=${JSON.stringify(query)}`,
      });
    },
    clearData () {
      this.detailData = {
        // 配送信息
        shippingInformation: {
          name: '',
          tel: '',
          detailAddress: '',
          address: '',
          location: '',
          axis: {
            latitudes: '',
            longitudes: '',
          },
        },
        drugManInformation: {
          name: '',
          personId: '',
        },
        patientId: '',
        patientName: '',
        diseaseList: [],
        hasAdverse: '', // 不良反应
        hasAllergy: '', // 过敏反应
        hasTake: '', // 禁忌
        product: [],
        invoice: '',
        fileUrlList: [], // 电子的
        prescription: [], // 纸质的
        freight: 10,

        personName: '',
        gender: '',
        age: '',
        phone: '',
        prescriptionType: '',
      };
      this.switchChecked = false;
      this.showPreInfo = false;
      this.showConsignee = true;
      this.productSum = 0;
      this.allSum = 0;
    },
    goAddress () {
      this.$router.push({
        name: 'add-address',
      });
    },
    switchChange () {
      if (this.switchChecked) {
        const param = {
          switchChecked: this.switchChecked,
          invoice: this.detailData.invoice,
        };
        this.addInvoiceInfo({ ...param });
      } else {
        this.clearInvoiceInfo();
      }
    },
    blurInvoice () {
      if (this.detailData.invoice) {
        const param = {
          switchChecked: this.switchChecked,
          invoice: this.detailData.invoice,
        };
        this.addInvoiceInfo({ ...param });
      } else {
        this.clearInvoiceInfo();
      }
    },
    verifyBeforePay () {
      if (
        !this.detailData.shippingInformation.name
        || !this.detailData.shippingInformation.tel
        || !this.detailData.shippingInformation.detailAddress
        || !this.detailData.shippingInformation.address
        || !this.detailData.shippingInformation.address
        || !this.detailData.shippingInformation.axis.latitudes
        || !this.detailData.shippingInformation.axis.longitudes
      ) {
        Toast('请选择收货地址');
        return true;
      }

      if (this.$store.state.prescription.currentTab === 0) {
        if (
          (!this.detailData.diseaseList.length
            || this.detailData.hasAdverse === ''
            || this.detailData.hasAllergy === ''
            || this.detailData.hasTake === '')
          && this.showPreInfo
        ) {
          Toast('您购买的商品中含处方药，请填写处方信息');
          return true;
        }
      }

      if (this.$store.state.prescription.currentTab === 1) {
        if (!this.detailData.prescription && this.showPreInfo) {
          Toast('您购买的商品中含处方药，请填写处方信息');
          return true;
        }
      }

      if (this.switchChecked && !this.detailData.invoice) {
        Toast('请填写发票抬头');
        return true;
      }

      return false;
    },
    handleConfirm () {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './mixin.scss';

.pay_box /deep/ .van-popup {
  height: 80%;
}
.pay_box /deep/ .van-popup .van-action-sheet__content {
  height: 100% !important;
}

.pay_box /deep/ .van-popup .van-action-sheet__content .content {
  height: 100% !important;
}
.pay_box {
  position: relative;
  padding-top: 8px;
  padding-bottom: 48px;
  .addr_info {
    position: relative;
    background: #fff;
    margin-bottom: 8px;
    .add_addr {
      height: 56px;
      // line-height: 56px;
      text-align: center;
      .btn_addr {
        margin-top: 11px;
        height: 32px;
        background: linear-gradient(128deg, #ffd169 0%, #fd6500 100%);
        border-radius: 21px;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 15px;
        color: #fff;
        border: none;
      }
    }

    .addressee {
      font-size: 16px;
      position: relative;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 16px;
      padding-bottom: 12px;
      .namephone {
        // height: 22px;
        line-height: 22px;
        padding: 18px 18px 8px;
        font-weight: 600;
        color: #333;
        display: flex;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 2px;
          background: url('~@img/phone.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 8px;
        }
        .name_phone {
          flex: 1;
        }
        .name {
          margin-right: 16px;
        }
        // .phone {
        //     padding-left: 16px;
        // }
      }
      .address {
        display: flex;
        padding: 0 18px;
        .address_txt {
          color: #333;
          font-weight: 400;
          line-height: 22px;
          width: 287px;
          word-wrap: break-word;
          word-break: break-all;
          white-space: pre-wrap;
        }
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 2px;
          background: url('~@img/icon_location.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 8px;
        }
      }
    }
    .group {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
    }
  }
  .pre_info {
    margin-bottom: 8px;
    background: #fff;
    padding: 16px 18px;
    .fill_pre {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left_fill {
        .fill_in {
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #333;
          line-height: 22px;
          margin-bottom: 4px;
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            position: relative;
            top: 1px;
            background: url('~@img/rp.png') no-repeat;
            background-size: 16px 16px;
            margin-right: 3px;
          }
        }
        .txt_in {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #62636c;
          line-height: 20px;
        }
      }
      .right_fill {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right_btn {
        // width: 74px;
        height: 32px;
        line-height: 32px;
        background: #fff;
        border-radius: 16px;
        border: 1px solid #f36218;
        font-size: 14px;
        font-weight: 500;
        color: #f36218;
        text-align: center;
        margin-top: 4px;
      }
    }
  }
  .completed_pre {
    background: #fff;
    padding: 16px 15px 18px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    .completed_info {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      .completed_title,
      .completed_name {
        height: 22px;
        font-size: 16px;
        color: #333;
        line-height: 22px;
        margin-bottom: 6px;
        .tag {
          display: inline-block;
          width: 44px;
          height: 16px;
          line-height: 16px;
          font-size: 11px;
          color: #fff;
          text-align: center;
          background: url('~@img/tag_bg.png') no-repeat;
          background-size: contain;
          margin-right: 5px;
        }
      }
      .completed_title {
        font-weight: 500;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          background: url('~@img/rp.png') no-repeat;
          background-size: 16px 16px;
          margin-right: 4px;
        }
      }
      .completed_name {
        font-weight: 400;
      }
      .completed_txt {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        line-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  .card {
    background: #fff;
    margin-bottom: 8px;
    .drug_list {
      padding: 0 18px;
      .num {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 20px;
      }
    }
  }

  .common_info {
    margin-bottom: 8px;
    background: white;
    .purchase {
      padding: 16px 18px;
      font-size: 14px;
      font-weight: 400;
      color: #62636c;
      p {
        line-height: 22px;
      }
    }
    .sw {
      margin-top: 9px;

      @include switchTag();
    }
    .coupon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: #666;
    }
    .arrow_container {
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow_right {
        width: 12px;
        height: 12px;
      }
    }
    .card_amount {
      font-size: 14px;
      font-weight: 500;
      color: #f0524f;
    }
    .unselected_coupon {
      font-size: 14px;
      font-weight: 500;
      color: #666;
    }
  }
  .footer_btns {
    position: fixed;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    bottom: 0;
    display: flex;
    width: 100%;
    height: 48px;
    line-height: 48px;
    box-shadow: 0 2px 8px 0 rgba(68, 134, 251, 0.12);
    .sum_btn {
      background: #fff;
      flex: 1;
      padding-left: 18px;
      font-size: 20px;
      font-weight: 500;
      color: #fa3e3e;
      position: relative;
      .sum_txt {
        font-size: 14px;
        font-weight: 400;
        color: #62636c;
        margin-right: 10px;
        position: relative;
        bottom: 1px;
      }
      .icon_money {
        font-size: 14px;
      }
      .money {
        font-size: 20px;
        font-weight: 500;
        color: #fa3e3e;
      }
      .origin_price {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.35);
        margin-left: 15px;
        text-decoration: line-through;
      }
    }
    .pay_btn {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      background: #f36218;
      width: 141px;
    }
  }
  .right_arrow {
    display: flex;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
    }
  }
}

/deep/ .van-field__control {
  text-align: right;
}
.tip {
  padding: 24px 22px;
  font-size: 16px;
  // font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
  line-height: 25px;
  background: #fff;
  text-align: center;
}
</style>
